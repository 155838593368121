/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { openDisclosureWindow } from "../../app/actions/standardActions";
import { putVariableDisclosures } from "../../app/thunks/worksheetThunks";
import { InputGroup, Form, Button } from "react-bootstrap";

const DisclosureSwitch = ({ item }: any) => {
  const dispatch = useAppDispatch();
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const discData: any = useAppSelector(
    (state) => state.callWorksheets.disclosures.data
  );
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const inProgress: any = useAppSelector((state) => state.features.putInProgress);

  const itemValue = () => {
    if (item.value === "agent" || item.value === "ivr") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <InputGroup id={`input_group_disc_${item.id}`}>
      <Form.Check
        type="switch"
        disabled={inProgress.status}
        id={`disc_${item.id}`}
        label={item.text}
        checked={itemValue()}
        onChange={(e) => {
          const disc = discData;
          if (e.currentTarget.checked === true) {
            const data: any = {};
            data.id = item.id;
            data.value = "agent";
            const array = [];
            array.push(data);
            dispatch(
              putVariableDisclosures(metadata.data, disc, callIds, array)
            );
          } else {
            const data: any = {};
            data.id = item.id;
            data.value = "false";
            const array = [];
            array.push(data);
            dispatch(
              putVariableDisclosures(metadata.data, disc, callIds, array)
            );
          }
        }}
      />
      <Button
        variant="link"
        className="p-0 script-launch"
        onClick={() => {
          dispatch(openDisclosureWindow(item.id));
        }}
      >
        Script
      </Button>
    </InputGroup>
  );
};

export default DisclosureSwitch;
