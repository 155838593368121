import { createSlice} from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt, reportError } from "../../log";



const initialState = {
  safeHaven: true,
};

export const hoursOfOperation = createSlice({
  name: "hoursOfOperation",
  initialState,
  reducers: {
    safeHavenClosed: (state) => {

      if(state.safeHaven !== false) {
        consoleLogIt("Safe Haven Close");
        state.safeHaven = false;
      }

    },
    safeHavenOpen: (state) => {

      if(state.safeHaven !== true) {
        consoleLogIt("Safe Haven Open");
        state.safeHaven = true;
      }
    }
  },
  extraReducers:{}
});
// thunks

// Action creators are generated for each case reducer function
export const {
  safeHavenClosed,
  safeHavenOpen,
} = hoursOfOperation.actions;

export default hoursOfOperation.reducer;
