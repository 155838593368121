import { createSlice, createAction, nanoid } from "@reduxjs/toolkit";
import { GET_CALLDATA_SUCCESS } from "../../actions/apisActions";
import { UPDATE_INCENTIVES_VARIABLE_SUCCESS } from "../../actions/worksheetActions";
import { IncentiveOptionsObject } from "../../types";

const initialState = {
  refiWS: "null",
  refiO: "null",
  refiR: "null",
  othWS: "null",
  othO: "null",
  othR: "null",
  allowed: "true",
    otherLoan: "null",
} as IncentiveOptionsObject;

export const incentiveOptions = createSlice({
  name: "incentiveOptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GET_CALLDATA_SUCCESS, (state, action) => {
        const { payload }: any = action;
        // frontline incetive options parse
        const data = JSON.parse(payload.data.variables["1248"]);
        // freedom loan variable
        const freedomLoan = payload.data.activeContact.fields["1380"];
        //? other loans variable??
        const otherIncentives = payload.data.activeContact.fields["1162"];
        // show incentive switch 300000000000029
        state.allowed = payload.data.variables["300000000000029"];
        if (
          freedomLoan === "C" ||
          freedomLoan === "T" ||
          freedomLoan === "G" ||
          freedomLoan === "A"
        ) {
          state.refiWS = "F Refi";
        } else if (freedomLoan === "O") {
          state.refiWS = "F CO";
        } else if (freedomLoan === "R") {
          state.refiWS = "Reverse";
        } else {
          state.refiWS = "null";
        }

        if (otherIncentives === "M" || otherIncentives === "R" || otherIncentives === "E" || otherIncentives === "W" ) {
          state.othWS = "M HOI";
                    state.otherLoan = otherIncentives;
        } else if (otherIncentives === "S") {
          // S Is a placeholder until the real indicator is given
          state.othWS = "SHaven";
        } else {
          state.othWS = "null";
        }

        state.refiO = data.refiO;
        state.refiR = data.refiR;
        state.othO = data.othO;
        state.othR = data.othR;
      })
      .addCase("azure/contactFields/succeeded", (state, action) => {
        const { payload }: any = action;
        const freedomLoan = payload.data.items[0].freedomLoan;
        const otherIncentives = payload.data.items[0].otherLoans;

        if (
          freedomLoan === "C" ||
          freedomLoan === "T" ||
          freedomLoan === "G" ||
          freedomLoan === "A"
        ) {
          state.refiWS = "F Refi";
        } else if (freedomLoan === "O") {
          state.refiWS = "F CO";
        } else if (freedomLoan === "R") {
          state.refiWS = "Reverse";
        } else {
          state.refiWS = "null";
        }

        if (otherIncentives === "M" || otherIncentives === "R" || otherIncentives === "E" || otherIncentives === "W") {
          state.othWS = "M HOI";
          state.otherLoan = otherIncentives;          

        } else if (otherIncentives === "S") {
          // S Is a placeholder until the real indicator is given
          state.othWS = "SHaven";
        } else {
          state.othWS = "null";
        }
      })
      .addCase(UPDATE_INCENTIVES_VARIABLE_SUCCESS, (state, action) => {
        const { payload }: any = action;
        const data = JSON.parse(payload[1248]);
        state.refiO = data.refiO;
        state.refiR = data.refiR;
        state.othO = data.othO;
        state.othR = data.othR;
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default incentiveOptions.reducer;
