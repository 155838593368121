/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";

import {
  updateConferenceObjectState,
  createConferenceObject,
} from "../../../app/actions/apisActions";
import { closeWebsocketModal } from "../../../app/actions/standardActions";
import { pingApi } from "../../../app/thunks/otherThunks";
import { variablesUpdatedV2 } from "../../../app/actions/standardActions";
import { consoleErrorIt, consoleLogIt } from "../../../app/log";
import WebSocketModal from "./WebSocketModal";
import HoldTimer from "./threshold/HoldTimer";
import ACWTimer from "./threshold/ACWTimer";
import hoursOfOperation from "../../../app/slices/main/hoursOfOperation";

const randomString = (length: number, chars: string) => {
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

const rString = randomString(
  10,
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
);

const WebSocketOpen = () => {
  // global states
  const dispatch = useAppDispatch();
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const websocketModal: boolean = useAppSelector(
    (state) => state.features.websocketModal
  );



  // local states
  const socketUrl = `wss://${metadata.data.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/ws/${rString}`;
  const [messageHistory, setMessageHistory] = useState([]);
  const [onHold, setOnHold] = useState(false);
  const [acw, setAcw] = useState(false);

  const ws: any = useRef(null);



  useEffect(() => {
    ws.current = new WebSocket(socketUrl);
    ws.current.onopen = () => {
      consoleLogIt("WS Opened");
    };
    ws.current.onclose = () => {
      consoleLogIt("WS Closed");
    };

    ws.current.onmessage = (event: any) => {
      const json = JSON.parse(event.data);
      //
      switch (json.context.eventId) {
        case "3":
          consoleLogIt("CONFERENCE_PARTICIPANT_RINGING", event);
          if (
            json.context.eventReason === "CONFERENCE_PARTICIPANT_RINGING" &&
            json.payLoad.id !== callIds.systemCallId
          ) {
            dispatch(
              createConferenceObject({
                one: {
                  eventId: json.context.eventId,
                  participant: 1,
                  id: callIds.systemCallId,
                  state: "ON_HOLD",
                  event: json.context.eventReason,
                },
                two: {
                  eventId: json.context.eventId,
                  participant: 2,
                  id: json.payLoad.id,
                  state: json.payLoad.state,
                  event: json.context.eventReason,
                },
              })
            );
          }
          break;
        case "4":
          consoleLogIt(json.context.eventReason, event);
          if (
            json.context.eventReason ===
              "CONFERENCE_PARTICIPANT_DISCONNECTED_BY_AGENT" ||
            json.context.eventReason === "CONFERENCE_PARTICIPANT_ADDED" ||
            json.context.eventReason ===
              "CONFERENCE_PARTICIPANT_DISCONNECTED_BY_THEMSELF"
          ) {
            dispatch(
              updateConferenceObjectState({
                eventId: json.context.eventId,
                event: json.context.eventReason,
                id: json.payLoad.id,
                state: json.payLoad.state,
              })
            );
          }
          // Section is for the on hold notification only.
          try{
          if (json.payLoad.state === "ON_HOLD") {
            setOnHold(true);
          } else { 
            setOnHold(false)
          }
        } catch (e) {
          consoleErrorIt("4 event Failed", e)
        }
        // end on hold notification section

          if (json.context.eventReason === "DISCONNECTED_BY_AGENT" || json.context.eventReason === "DISCONNECTED_BY_CALLER") {
            setAcw(true)
          }

          if (json.context.eventReason === "VARIABLES_UPDATED_V2") {
            dispatch(variablesUpdatedV2(json.payLoad))
          }

          break;
        case "5":
          consoleLogIt(json.context.eventReason, event);
          if (
            json.context.eventReason === "DISCONNECTED_BY_CALLER" ||
            json.context.eventReason === "REMOVED_FROM_CONFERENCE_BY_AGENT"
          ) {
            dispatch(
              updateConferenceObjectState({
                eventId: json.context.eventId,
                event: json.context.eventReason,
                id: json.payLoad.id,
                state: json.payLoad.state,
              })
            );
          }
          break;
        default:
          break;
      }

      setMessageHistory((prev) => prev.concat(json));
    };
    const wsCurrent = ws.current;

    const handleUnload: any = () => {
      consoleLogIt("Handle Unload Close Socket");
      wsCurrent.close();
    };
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(pingApi(metadata.data));
    }, 15000);

    const handleUnload: any = () => {
      consoleLogIt("Handle Unload Clear Interval");
      clearInterval(interval);
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  return (
    <>
      {onHold === true ? <HoldTimer time={180} /> : <></>}
      {acw === true ? <ACWTimer time={180} /> : <></>}
      <WebSocketModal
        messageHistory={messageHistory}
        modalShow={websocketModal}
        closeModal={() => dispatch(closeWebsocketModal())}
      />
    </>
  );
};

export default WebSocketOpen;
